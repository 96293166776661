/*----------------------
    Team Style  
-------------------------*/
.rn-box-card,
.team-style-default {
    margin-top: 75px;
    .inner {
        background: linear-gradient(180deg,var(--color-lessdark) 20%,rgba(19,19,19,.4));
        -webkit-backdrop-filter: blur(4px) opacity(.95);
        backdrop-filter: blur(4px) opacity(.95);
        border-radius: var(--radius-big);

        .thumbnail {
            max-width: 150px;
            max-height: 150px;
            border-radius: 100%;
            border: 6px solid var(--color-lessdark);
            top: -75px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            min-width: 150px;
            min-height: 150px;
            overflow: hidden;
            // img {
            //     border-radius: 100%;
            // }
        }

        .content {
            text-align: center;
            padding: 10px;
            padding-top: 85px;
            // padding-bottom: 35px;

            .title {
                font-size: 27px;
                line-height: 1.4;
                font-weight: 700;
                margin-bottom: 6px;
            }
            .subtitle {
                font-size: 20px;
                line-height: 1.4;
                margin-bottom: 15px;
                display: inline-block;
            }
            .team-form {
                display: block;
                font-size: 14px;
                margin-bottom: 12px;
                letter-spacing: 0.6px;
                font-style: italic;

                img {
                    padding-right: 10px;
                    max-height: 18px;
                }
            }
            .description {
                margin-bottom: 0;
                padding: 0 0%;
                text-align: center;
            }
        }

        .social-icon {
            li {
                span {
                    color: var(--color-body);
                }
            }
        }
    }

    &.border-gradient {
        .thumbnail {
            background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 98%/200% 100%;
            padding: 5px;
            border: 0 none;
        }
    }

    &.style-two {
        margin-top: 30px;
        .inner {
            padding-top: 35px;
            .thumbnail {
                top: 0;
                position: static;
                left: inherit;
                transform: none;
                min-width: 150px;
                min-height: 150px;
                margin: 0 auto;
            }
            .content {
                padding-top: 10px;
            }
        }
    }
}

/*---------------------
    Team Style Two  
-----------------------*/
.team-style-two {
    .inner {
        .thumbnail {
            img {
                width: 100%;
                height: 38rem;
                object-fit: cover;
                border-radius: var(--radius);
            }
        }
        .content {
            padding-top: 20px;
            .title {
                font-size: 28px;
                line-height: 1.4;
                font-weight: 500;
                margin-bottom: 0;

            }
            .subtitle {
                font-size: 14px;
                line-height: 1.4;
                display: inline-block;
                margin-top: 0px;
                margin-bottom: 0;
                font-weight: 700;
                // opacity: 0;
                // transform: translateX(30px);
                // transition: transform .45s cubic-bezier(.16,.32,.26,.92),opacity .45s ease-out;
                // transition-delay: .15s;
            }
            .team-form {
                display: block;
                font-size: 14px;
                letter-spacing: 0.6px;
                font-style: italic;
                margin-top: 11px;
                img {
                    padding-right: 10px;
                    max-height: 18px;
                }
            }
        }
    }

    &:hover {
        .inner {
            .content {
                

                .subtitle {
                    opacity: 1;
                    transform: none;
                }

            }
        }
    }
}

/*---------------------
    Team Style Three  
-----------------------*/

.team-style-three {
    .inner {
        position: relative;
        .thumbnail {
            img {
                width: 100%;
                border-radius: var(--radius);
            }
        }
        .content {
            position: absolute;
            bottom: 0;
            padding: 20px;
            width: 100%;
            transition: all 0.3s;
            opacity: 0;

            .team-info {
                padding: 20px;
                background-color: rgba(58,58,58,0.85);
                border-radius: var(--radius-small);
            }

            .title {
                font-size: 17px;
                line-height: 25px;
                color: var(--color-heading);
                margin-bottom: 4px;
            }
            .subtitle {
                font-size: 16px;
                line-height: 25px;
                margin-bottom: 4px;
            }
            .team-form {
                display: block;
                font-style: italic;
                font-size: 14px;
                color: #ADADAD;
                letter-spacing: 0.5px;
            }
        }
    }

    &:hover {
        .inner {
            .content {
                opacity: 1;
            }
        }
    }
}
.letterSize{
    font-weight: 800;
   
}

.carousel__container {
    max-width: 1000px;
    margin: auto;
  }
  
  /* gives us the illusion of a "centered" slide */
  .carousel__slider {
    padding-left: 16%;
    padding-right: 16%;
  }
  
  /* gives us the illusion of spaces between the slides */
  .carousel__inner-slide {
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    left: 10px;
    top: 10px;
    // background-color: burlywood;
  }
  .react-3d-carousel .slider-container .slider-content .slider-single .slider-single-content img {
    min-width: 50rem !important;
    height: 60rem;
    object-fit: cover;
    
}
.react-3d-carousel .slider-container {
   
    width: 100%;
   
}
.react-3d-carousel {
   
    z-index: 1 !important;
}
  
.react-3d-carousel .slider-container .slider-content {
    
    width: 100% !important;
    height: 80% !important;
   
}
.react-3d-carousel .slider-container .slider-right div {
   
    border: 0px solid #800000 !important;
    border: none;
   border-radius: 20px;
}
.react-3d-carousel .slider-container .slider-left div {
   
    border: 0px solid #800000 !important;
    border-radius: 20px;
}