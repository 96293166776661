/*--------------------
    Service Styles  
----------------------*/

.service {
  &.service__style--1 {
    height: fit-content;
    padding: 30px 35px;
    padding-bottom: 55px;
    transition: transform 0.65s cubic-bezier(0.05, 0.2, 0.1, 1),
      box-shadow 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
    position: relative;
    z-index: 2;
    @media #{$laptop-device} {
      padding: 15px 16px;
    }
    @media #{$md-layout} {
      padding: 30px 25px;
    }
    @media #{$sm-layout} {
      padding: 10px 10px;
      margin: 0;
    }

    .icon {
      @extend %transition;
      font-size: 45px;
      font-weight: 400;
      margin-bottom: 23px;
      color: var(--color-primary);
      text-align: center;
      border-radius: 100%;
      display: flex;
      justify-content: center;

      @media #{$md-layout} {
        margin-bottom: 11px;
      }
      svg {
        stroke-width: 1 !important;
      }
    }

    .content {
      .title {
        margin-bottom: 19px;
        @extend %transition;
        @media #{$md-layout} {
          margin-bottom: 8px;
        }
        a {
          color: inherit;
          @extend %transition;
          &:hover {
            color: var(--color-primary);
          }
        }
      }
      p {
        @extend %transition;
      }
    }

    &:hover {
      transform: translateY(-5px);
    }

    &.rbt-border {
      border: 1px solid var(--color-border);
    }

    &.text-start {
      padding: 50px;
      @media #{$laptop-device} {
        padding: 40px 30px;
      }
      @media #{$lg-layout} {
        padding: 40px 30px;
      }
      @media #{$md-layout} {
        padding: 40px 30px;
      }
      @media #{$sm-layout} {
        padding: 30px 20px;
      }

      .icon {
        justify-content: left;
      }
    }

    &.icon-circle-style {
      background-color: transparent;
      box-shadow: none;
      padding: 0px 35px;
      margin-top: 25px;

      @media #{$md-layout} {
        padding: 0px 10px;
      }

      @media #{$sm-layout} {
        padding: 0px 10px;
      }

      .icon {
        font-size: 39px;
        color: white;
        display: flex;
        justify-content: center;
        width: 90px;
        height: 90px;
        margin: 0 auto;
        margin-bottom: 23px;
        background-color: #111;
        line-height: 90px;
        align-items: center;
      }
    }

    &.with-working-process {
      position: relative;
      @media #{$lg-layout} {
        padding: 20px 0;
      }
      .icon {
        width: 180px;
        height: 180px;
        line-height: 180px;
        font-size: 60px;
        position: relative;
      }
      .line {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 1px;
        width: 100%;
        left: 100%;
        background-color: var(--color-border);
        @media #{$md-layout} {
          display: none;
        }
        @media #{$sm-layout} {
          display: none;
        }
      }
      &:hover {
        transform: none;
      }
    }
  }

  &.gallery-style {
    padding: 13px 12px;
    background: var(--color-blackest);
    border-radius: 15px;
    box-shadow: 0px 1px 1px rgba(255, 255, 255, 0.1);
    margin-top: 10px;
    @media (max-width: 768px) {
      margin-top: 10px; 
  }
    .content {
      // height: 13rem;
      .image {
        img {
          border-radius: 5px;
          

        }
      }
      .title {
        margin-bottom: 19px;
        font-weight: 600;
        font-size: 20px;
        @extend %transition;
        margin-bottom: 10px;
        a {
          color: inherit;
          @extend %transition;
          &:hover {
            color: var(--color-primary);
          }
        }
      }
      p {
        @extend %transition;
        color: var(--color-body);
        margin-bottom: 0px;
      }
    }
  }

  &.service__style--2 {
    padding: 50px 35px;
    padding-bottom: 55px;
    transition: transform 0.65s cubic-bezier(0.05, 0.2, 0.1, 1),
      box-shadow 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
    position: relative;
    z-index: 2;
    border-radius: 10px;
    margin-top: 30px;

    @media #{$laptop-device} {
      padding: 10px 28px;
    }

    @media #{$lg-layout} {
      padding: 10px 15px;
    }

    @media #{$md-layout} {
      padding: 10px 25px;
    }

    @media #{$sm-layout} {
      padding: 0px 18px;
    }
    .image {
      // margin-bottom: 23px;
      img {
        max-height: 220px;
      }
    }
    .content {
      .title {
        @extend %transition;
        a {
          color: inherit;
          @extend %transition;
          &:hover {
            color: var(--color-primary);
          }
        }
      }
      p {
        @extend %transition;
        padding: 0 5%;
      }
    }
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 0 39px rgba(255, 101, 80, 0.1);
    }
  }
}

.service-wrapper {
  & > [class*='col'] {
    &:nth-child(2) {
      .service {
        &.service__style--1 {
          .icon {
            color: white;
          }
        }
      }
    }

    &:nth-child(3) {
      .service {
        &.service__style--1 {
          .icon {
            color: white;
          }
        }
      }
    }

    &:nth-child(4) {
      .service {
        &.service__style--1 {
          .icon {
            color: white;
          }
        }
      }
    }
  }

  & > [class*='col'] {
    &:nth-child(2) {
      .service {
        &.service__style--1 {
          &.icon-circle-style {
            .icon {
              background-color: #111;
            }
          }
        }
      }
    }

    &:nth-child(3) {
      .service {
        &.service__style--1 {
          &.icon-circle-style {
            .icon {
              background-color: #111;
            }
          }
        }
      }
    }

    &:nth-child(4) {
      .service {
        &.service__style--1 {
          &.icon-circle-style {
            .icon {
              background-color: #111;
            }
          }
        }
      }
    }

    &:last-child {
      .with-working-process {
        .line {
          display: none;
        }
      }
    }
  }
}

.all-solution-divs-height {
 h4 {
  transition: all 400ms;

  &:hover {
    color: #800000;
  }
}

}

