/*----------------------
    Blog Styles  
-----------------------*/
.rn-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: var(--color-blackest);
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: var(--radius);
    overflow: hidden;
    transition: all .5s;

    .inner {
        .thumbnail {
            a {
                img {
                    width: 100%;
                    height: auto;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        .content {
            display: flex;
            flex-wrap: wrap;
            height: 100%;
            padding-top: 4px;
            padding-bottom: 4px;
            text-align: center;

            @media #{$sm-layout} {
                padding: 0px 0px;
            }

            .descriptiion {
                margin-bottom: 0;
                // margin-bottom: 13px;
                margin-left: 10px;
            }

            .title {
                font-size: 20px;
                line-height: 1.5;
                transition: all .5s;
                margin-bottom: 0;
                margin-top: 15px;
                flex-basis: 100%;
            }

        }

    }

    &.card-list-view {
        height: 100%;

        .inner {
            display: flex;
            height: 100%;
            padding: 10px;

            @media #{$sm-layout} {
                display: contents;
            }

            .thumbnail {
                flex-basis: 50%;

                a {
                    display: block;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 300px;
                        // object-fit: cover;
                        height: 180px;
                        // margin-top: 20px;
                        // margin-left: 10px;

                    }
                }
            }

            .content {
                flex-basis: 50%;

                .title {
                    margin-bottom: 5px;
                    font-size: 18px;
                }

                .descriptiion {
                    // margin-bottom: 13px;
                    margin-top: 1px;
                }
            }
        }
    }
}


.rn-meta-list {
    @extend %liststyle;
    font-size: 1.4rem;
    line-height: 2.2rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    // margin-left: 27%;


    li {
        margin-top: 0;
        margin-bottom: 0;
        color: var(--color-gray);
        font-size: 14px;

        a {
            color: var(--color-gray);
            transition: 0.5s;
        }

        &.separator {
            margin: 0 .8rem;
        }
    }
}