.career-h1 {
  font-size: 41px;
  @media #{$sm-layout} {
    font-size: 20px;
   
}
}

.career-p {

  display: flex;
  // align-items: center;

  // font-size: 18px;
  @media only screen and (max-width: 600px) {
    padding-left: 10px;
    padding-right: 8px;
text-align: left;
  }


}

.career-one {

  border-bottom: 15px solid #800000;
}

.full-time {
  color: #800000;
  letter-spacing: 0.5px;
  font-weight: 600;
  font-family: sans-serif;
}

.career-one-content {
  p{
    font-size: 18px !important;

  }
  @media screen and (max-width: 767px) {
    margin-top: 0px;
  }
}

.career-top-content {
  @media screen and (min-width: 767px) and (max-width:1020px) {
    width: 100%;
  }
}

.career-top-img {
  margin-top: 75px;

  @media screen and (max-width:766px) {
    margin-top: -20px;
  }

  @media screen and (min-width: 767px) and (max-width:1020px) {
    margin-top: 20px;
    width: 90%;
  }
}

.career-side-img {
  margin-left: 20px;

  @media screen and (max-width:767px) {
    margin-left: 0px;
  }

  @media screen and (min-width: 768px) and (max-width:1020px) {
    margin-left: 170px;
    margin-bottom: 20px;
  }
}

.career-one-bottom {
  background-color: #800000;
  height: 50px;
  border-radius: 50rem;
  position: relative;
  margin-top: -35px;
  z-index: 3;

  p {
    text-align: center;
    margin-top: 11px;
    font-size: 18px;
    font-weight: bold;

    @media screen and (max-width: 1023px) {
      font-size: 15px;
    }
  }

  @media screen and (min-width: 1024px) {
    .career-one-bottom {
      width: 300px;
    }
  }
}


.career-last {

  width: 98%;
  padding: 15px;
  margin-bottom: 10px;
  margin-left: 10px;

  @media screen and (max-width: 767px) {
    width: 96%;
    margin-left: 3%;
  }

  @media screen and (min-width:768px) and (max-width: 1020px) {
    width: 97%;
    margin-left: 2%;
  }
}

.career-btn {


  @media screen and (max-width: 767px) {
    margin-top: 10px;
    margin-bottom:0px;
  }

}

.openings {
  @media screen and (min-width: 767px) and (max-width:1020px) {
    width: 100%;
  }
}


.career-four {
  margin-top: 25px;
  margin-bottom: 20px;
}


// hcm section css
.containerStyle {
  display: flex
}



.imageStyle {
  height: 480px;
  width: 640px
}



.logoStyle {
  margin-right: 5px;
  font-size: 18px;
  color: #800000
}


.hcm_main_one {
  padding-left: 0px;
  padding-right: 0px;
// p{
//   font-size: 18px;
// }
  @media (max-width: 373px) {
    padding-left: 5px;
    padding-right: 5px;
  }

  @media (min-width:374px) and (max-width: 420px) {
    padding-left: 8px;
    padding-right: 8px;
  }

  @media (min-width:423px) and (max-width:765px) {
    padding-left: 11px;
    padding-right: 11px;
  }
}

.hcm_main_two {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider_bottom {
  padding-bottom: 40px;


  @media (max-width: 373px) {
    padding-bottom: 30px;
  }

  @media (min-width:374px) and (max-width: 420px) {
    padding-bottom: 10px;
  }

  @media (min-width:423px) and (max-width:765px) {
    padding-bottom: 0px;
  }


}

@media screen and (max-width: 767px) {
  .containerStyle {
    .hcm_points {
      p {
        text-align: center;
      }

      margin-left: 8%;
    }
  }



  .imageStyle {
    height: 280px;
    width: 350px;
    // margin-left: 3%;

  }

  .logoStyle {
    font-size: 16px;
    margin-left: 10px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .hcm_main_one {
    width: 100%;
  }

  .imageStyle {
    height: 280px;
    width: 640px
  }

  .containerStyle {
    flex-direction: column;
  }

  // .hcm_main_two {
  //   width: 65%;
  //   margin-left: 20%;
  // }
}

// portfolio image
.portfolioImg {
  margin-left: 17%;
  width: auto;
  height: auto;
}

@media screen and (max-width: 767px) {
  .portfolioImg {
    margin-left: 1%;

  }
}



// circle progress home page
.circle_bar {
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 373px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
 .circle-info h3 {
      font-size: 14px !important;
    }
  }

  @media (min-width:374px) and (max-width: 420px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .circle-info h3 {
      font-size: 16px !important;
    }
  }

  @media (min-width:423px) and (max-width:765px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .circle-info h3 {
      font-size: 16px !important;
    }
  }
  @media (min-width:767px) and (max-width:1020px) {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    .circle-info h3 {
      font-size: 16px !important;
    }
  }
}


.circle_main {
  display: flex;
  align-items: center;
  justify-content: center;
  // @media (max-width: 373px) {

  //   display: flex;
  //   align-items: center;
  //   justify-content: center;

  // }

  // @media (min-width:374px) and (max-width: 420px) {

  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  // }

  // @media (min-width:423px) and (max-width:765px) {

  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  // }
}