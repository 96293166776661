@import "bootstrap/dist/css/bootstrap.min.css";@import url(../css/bootstrap/bootstrap-utilities.css);@import url(../css/bootstrap/bootstrap-grid.css);@import url(../css/slick.css);@import url(../css/slicktheme.css);@import url(../css/animation.css);@import "default/variables";@import "default/mixins";@import "default/reset";@import "default/typography";@import "default/extend";@import "default/animations";@import "default/shortcode";@import "default/spacing";@import "default/common";@import "default/forms";@import "color/themebg";@import "header/header";@import "header/nav";@import "header/mobilemenu";@import "header/headertop";@import "elements/sctiontitle";@import "elements/button";@import "elements/breadcrumb";@import "elements/accordion";@import "elements/service";@import "elements/card";@import "elements/progressbar";@import "elements/counter";@import "elements/portfolio";@import "elements/social";@import "elements/team";@import "elements/timeline";@import "elements/testimonial";@import "elements/slickslide";@import "elements/tab";@import "elements/split";@import "elements/callto-action";@import "elements/video";@import "elements/contact";@import "elements/advance-tab";@import "elements/advancepricing";@import "elements/about";@import "elements/swicher";@import "elements/404";@import "blog/blog";@import "blog/sidebar";@import "blog/blog-details";@import "template/banner";@import "template/portfoliodetails";@import "template/splash";@import "footer/footer";@import "footer/copyright";@import "footer/darkmode";@import "service/service";@import "service/career"; ::-webkit-scrollbar{width:6px;scroll-behavior:smooth}::-webkit-scrollbar-track{background:#000}::-webkit-scrollbar-thumb{background:#333}::-webkit-scrollbar-thumb:hover{background:#333}.pointer{cursor:pointer!important} 

/**************************************
    Default Styles
***************************************/

// @import 'bootstrap/dist/css/bootstrap.min.css';

//already comment  @import url('../css/bootstrap/bootstrap-utilities.css');
//already comment @import url('../css/bootstrap/bootstrap-grid.css');

// @import url('../css/slick.css');
// @import url('../css/slicktheme.css');
// @import url('../css/animation.css');
// @import 'default/variables';
// @import 'default/mixins';
// @import 'default/reset';
// @import 'default/typography';
// @import 'default/extend';
// @import 'default/animations';
// @import 'default/shortcode';
// @import 'default/spacing';
// @import 'default/common';
// @import 'default/forms';

/* width */
// ::-webkit-scrollbar {
//   width: 6px;
//   scroll-behavior: smooth;
// }

/* Track */
// ::-webkit-scrollbar-track {
//   background: #000;
// }

/* Handle */
// ::-webkit-scrollbar-thumb {
//   background: #333;
// }

/* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: #333;
// }
// .pointer {
//   cursor: pointer !important;
// }

//already comment img {
//   border-radius: 24px !important;
// }

/**************************************
    Theme Styles
***************************************/

// @import 'color/themebg';

/**************************************
    Header Styles
***************************************/
// @import 'header/header';
// @import 'header/nav';
// @import 'header/mobilemenu';
// @import 'header/headertop';

/**************************************
    Elements Styles
***************************************/
// @import 'elements/sctiontitle';
// @import 'elements/button';
// @import 'elements/breadcrumb';
// @import 'elements/accordion';
// @import 'elements/service';
// @import 'elements/card';
// @import 'elements/progressbar';
// @import 'elements/counter';
// @import 'elements/portfolio';
// @import 'elements/social';
// @import 'elements/team';
// @import 'elements/timeline';
// @import 'elements/testimonial';
// @import 'elements/slickslide';
// @import 'elements/tab';
// @import 'elements/split';
// @import 'elements/callto-action';
// @import 'elements/video';
// @import 'elements/contact';
// @import 'elements/advance-tab';
// @import 'elements/advancepricing';
// @import 'elements/about';
// @import 'elements/swicher';
// @import 'elements/404';

/**************************************
    Blog Styles
***************************************/

// @import 'blog/blog';
// @import 'blog/sidebar';
// @import 'blog/blog-details';

/**************************************
    Footer Styles
***************************************/
// @import 'template/banner';
// @import 'template/portfoliodetails';
// @import 'template/splash';

/**************************************
    Footer Styles
***************************************/

// @import 'footer/footer';
// @import 'footer/copyright';
// @import 'footer/darkmode';
// @import 'service/service';
// @import 'service/career'
