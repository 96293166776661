#form-cont {
    /* background: linear-gradient(to top, #5e0606, black); */
    height: 100vh;
    width: 100vw;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: 2fr 3fr;
    justify-items: center;
    align-items: center;
}
/* .contact-form-2 {
     margin-top: 55px; 
     .form-group {
      input {
        height: 70px;
        width: 90%;
        padding: 0 20px;
     text-decoration: none;
      }
     
      textarea {
       display: none;
      
      }
    }
  } */


#form-cont section.section-1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* background-color: rgba(110, 34, 34, 0.456); */
    height: 100vh;
    width: 100%;
}

#form-cont section.section-1 img.logo.child-1 {
    padding-top: 30px;
    filter: brightness(0.9) saturate(0.9);
}

#form-cont section.section-1 img.desk.child-2 {
    max-width: 90%;
    height: auto;
}

#form-cont section.section-1 div.child-3,
#form-cont section.section-2 div.child-3 {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 20px 0;
}

#form-cont section.section-1 div.child-3 span.links span,
#form-cont section.section-2 div.child-3 span.links span {
    padding: 0 8px;
}

#form-cont section.section-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: rgba(166, 59, 59, 0.297); */
    
    width: 100%;
    height: 100vh;
}

#form-cont section.section-2 form.child-1 {
    display: flex;
    flex-direction: column;
}

form.child-1 {
    display: flex;
    flex-direction: column;
    max-width: 420px;
    width: 100%;
    /* background-color: rgba(255, 255, 255, 0.5); */
background-color: transparent;
    backdrop-filter: blur(10px);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

form.child-1 label {
    padding: 10px 0;
    font-weight: bold;
    /* color: #333; */
    color: white;
}

form.child-1 input {
    margin-top: 22px;
    margin-bottom: 5px;
    padding: 10px 20px;
    /* border: none; */
    /* color: #333; */
    /* background-color: rgba(255, 255, 255, 0.8); */
    /* color: white; */
    border-radius: 22px;
    width: 100%;
    height: 65px;
    border: 2px solid #1a1a1a;
}

form.child-1 input:focus {
    /* outline: none; */
    background-color: transparent;
    border: 2px solid #800000;
}

form.child-1 input[type="number"]::-webkit-inner-spin-button,
form.child-1 input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

form.child-1 button[type="submit"] {
    /* background-color: #af4c4c; */
    /* color: white; */
    /* padding: 10px 15px; */
    border: none;
    /* border-radius: 4px; */
    /* cursor: pointer; */
 
        margin-left: 21%;
      margin-right: 20%;
        @media (max-width: 767px) {
          margin-left: 19%;
        }

}
/* 
form.child-1 button[type="submit"]:hover {
    background-color: #a04545;
} */

form.child-1 input.valid {
    /* border-color: green; */
}

form.child-1 input.invalid {
    /* border-color: red;/ */
}

form.child-1 input:valid {
    /* border: 1px solid green; */
  
}

form.child-1 input:invalid {
    /* border: 2px solid red; */
}

form.child-1 input:valid+label {
    /* color: green; */
}

form.child-1 input:invalid+label {
    /* color: red; */
}

form.child-1 .error {
    color: red;
    font-size: 14px;
    margin-top: 5px;
}

form.child-1 label.invalid {
    border: 2px solid red;
}

form.child-1 input.invalid {
    border: 1px solid red;
}

@media (max-width: 1250px) {
    #form-cont {
        grid-template-columns: 3fr 4fr;
    }
}

@media (max-width: 1250px) {
    #form-cont section.section-2 form.child-1 {
        width: 90%;
        max-width: 400px;
    }
}

@media (max-width: 1000px) {
    #form-cont {
        grid-template-columns: 1fr;
    }

    #form-cont section.section-1 {
        display: none;
    }
}


.demoImage{
    margin-left: 150px;
    @media (max-width: 768px) {
  width: 90%;
            margin-left: 0;
    }
}

.demoLogo{
    margin-left: 5px;
    @media (max-width: 768px) {

            margin-left: 2;
    }
}