.section-title {
    .subtitle {
        font-weight: 600;
        letter-spacing: 0.5px;
        line-height: 18px;
        font-size: 16px;
        border-radius: 4px;
        margin-bottom: 12px;
        display: inline-block;
        padding: 7px 14px;
        background-color: var(--color-blackest);
        box-shadow: var(--shadow-lighter);
        &.radius-rounded {
            border-radius: 100px;
        }
    }
    .title {
        font-size: 31px;
        font-weight: 600;
        @media (max-width: 370px ) {
            font-size: 19px;
        }
        @media (min-width:373px) and (max-width: 420px ) {
            font-size: 23px;
        }
        @media (min-width:423px) and (max-width:765px ) {
            font-size: 28px;
        }
    }
    .titleSpecial{
        line-height: 1.4 !important;
    }
    .description {
        br {
            @media #{$sm-layout} {
                display: none;    
            }
        }
    }

    p {
        &:empty {
            display: none;
        }
    }
}


.section-title-2 {
    .title {
        color: var(--color-body);
        display: block;
        border-bottom: 1px solid var(--color-border);
        padding-bottom: 19px;
        opacity: 0.6;
    }
}

.section-title_about {
   .typed_title{
    font-size: 26px;
    font-weight: 600;
    @media (max-width: 370px ) {
        font-size: 14px; margin-top: 10px;
    }
    @media (min-width:373px) and (max-width: 420px ) {
        font-size: 17px; margin-top: 12px;
    }
    @media (min-width:423px) and (max-width:765px ) {
        font-size: 19px;   margin-top: 14px;
    }
   }
    .title {
        font-size: 31px;
        font-weight: 600;
        @media (max-width: 370px ) {
         
            font-size: 19px;
        }
        @media (min-width:373px) and (max-width: 420px ) {
            font-size: 23px;
        }
        @media (min-width:423px) and (max-width:765px ) {
            font-size: 28px;
        }
    }
  
    .description {
        br {
            @media #{$sm-layout} {
                display: none;    
            }
        }
    }

    p {
        &:empty {
            display: none;
        }
    }


    
    @media (min-width:374px) and (max-width: 420px ) {
        padding-left: 1px;
        padding-right: 1px;
    }
    @media (min-width:423px) and (max-width:765px ) {
        padding-left: 2px;
        padding-right: 2px;
    }
}








