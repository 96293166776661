.page-heading {
  text-align: center;
  padding-bottom: 10px;
}

.page-heading h2 {
  font-size: 32px;
}
@media (max-width: 768px) {
  .page-heading h2 {
    font-size: 22px;
  }
}
.page-heading span {
  font-size: 20px;
}

.features {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.feature {
  flex-basis: calc(33.33% - 20px);
  margin-bottom: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  text-align: left;
  padding: 0px 8px;
  padding-bottom: 4rem;
}

.feature img {
  align-self: flex-start;
  width: 50px;
  height: 40px;
  margin-right: 10px;
}

.feature div {
  display: flex;
  flex-direction: column;
  width: 80%;
}

.feature h4 {
  font-size: calc(16px);
  padding-bottom: 8px;
}

.feature p {
  font-size: calc(16px);
  line-height: 1.8;
}

.feature h4,
.feature p {
  margin: 0;
}

/* Media queries for responsiveness */

@media (max-width: 990px) {
  .feature {
    flex-basis: calc(50%);
  }
}

@media (max-width: 570px) {
  .features {
    flex-direction: column;
  }
}

/*  */

.hero-feature {
  display: flex;
  margin: 20px 0;
  justify-content: space-around;
  flex-wrap: wrap;
  flex: 0 0 auto;
}


.hero-feature-reverse {
  flex-direction: row-reverse;
}

.hero-feature-img {
  flex-basis: 40%;
}

.hero-feature-img img {
  border-radius: 5px;
}

.hero-feature-text {
  margin-left: 10px;
  flex-basis: 40%;
}

.hero-feature-text h3 {
  font-size: 27px;
  font-weight: 300;
}
@media (max-width: 767px) {
  .hero-feature-text h3 {
    font-size: 25px;
    text-align: center;
    margin-top: 11px;
  }
  .hero-feature-text {
    margin-left: 0px;

  }
  .hero-feature-text p {
    font-size: 20px;
    text-align: center;
    margin-top: 0px;
    margin: 0 3px;
  }
}
.hero-feature-text p {
  font-size: calc(18px + 0.09vw);
  font-weight: 300;
  line-height: 1.6;
}

@media only screen and (max-width: 990px) {
  .hero-feature {
    display: block;
  }
}
